import get from 'core-web/util/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

// eslint-disable-next-line sonarjs/cognitive-complexity -- temporary
const OrganizationStructuredData = ({ application }) => {
    const appName = process.env.REACT_APP_NAME || '';
    const protocol = application.protocol ? application.protocol : '';
    const origin = application.origin ? application.origin : '';
    const baseUrl = protocol + origin;

    const organization = get(application, 'config.options.organization', {});
    const { name } = organization;
    const legalName = organization.legal_name;
    const { logo } = organization;
    const address = organization.address || {};
    const contactPoint = organization.contact_point || [];

    const schemas = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
    };

    if (name) {
        schemas.name = name;
    }

    if (legalName) {
        schemas.legalName = legalName;
    }

    if (baseUrl) {
        schemas.url = baseUrl;
    }

    if (logo) {
        schemas.logo = logo;
    }

    if (Object.values(address).filter((a) => a).length) {
        schemas.address = {};
        if (address.street) {
            schemas.address.streetAddress = address.street;
        }
        if (address.city) {
            schemas.address.addressLocality = address.city;
        }
        if (address.city) {
            schemas.address.postalCode = address.postal_code;
        }
        if (address.city) {
            schemas.address.addressCountry = address.country_code;
        }
    }

    if (contactPoint.length) {
        schemas.contactPoint = [];
        contactPoint.forEach((contact) => {
            const obj = {};
            obj['@type'] = 'ContactPoint';
            obj.contactType = contact.type;
            if (contact.email) {
                obj.email = contact.email;
            }
            if (contact.phone) {
                obj.telephone = contact.phone;
            }
            if (contact.fax) {
                obj.faxNumber = contact.fax;
            }
            if (Object.keys(address).length) {
                schemas.contactPoint.push(obj);
            }
        });
    }

    if (appName !== 'hylte') {
        const socials = get(application, 'config.footer.social.socials', []);
        if (socials.length) {
            schemas.sameAs = [
                ...socials.filter((s) => s.link && s.link.url && s.link.url.startsWith('http')).map((s) => s.link.url),
            ];
        }
    }

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schemas, null, 2)}</script>
        </Helmet>
    );
};

OrganizationStructuredData.propTypes = {
    application: PropTypes.object.isRequired,
};

export default OrganizationStructuredData;
