import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import OrganizationStructuredData from 'core-web/components/Meta/StructuredData/OrganizationStructuredData';

const WebsiteStructuredData = ({ application }) => {
    const protocol = application.protocol ? application.protocol : '';
    const origin = application.origin ? application.origin : '';
    const baseUrl = protocol + origin;

    const searchPath = application.config?.options?.paths?.search;
    const organizationName = application?.config?.options?.organization?.name;

    const schemas = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: baseUrl,
    };

    if (organizationName) {
        schemas.name = organizationName;
    }

    if (searchPath) {
        schemas.potentialAction = {
            '@type': 'SearchAction',
            target: `${baseUrl}${searchPath}?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
        };
    }

    return (
        <>
            <OrganizationStructuredData application={application} />
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemas, null, 2)}</script>
            </Helmet>
        </>
    );
};

WebsiteStructuredData.propTypes = {
    application: PropTypes.object.isRequired,
};

export default WebsiteStructuredData;
